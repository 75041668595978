import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import styles from './AgeVerification.module.scss';

const AgeVerification = () => {
  return (
    <Fragment>
      <div className={styles.background}>
        <div className={styles.content}>
          <img className={styles.icon} src="assets/embla-logo-main.webp" alt="Embla" />
          <h2 className={styles.title}>+18 Age Verification</h2>
          <p className={styles.paragraph}>This website is restricted.</p>
          <p className={styles.paragraph}>
            If you’re not over 18 or you’re underage according to the laws of your country, you are not allowed to use
            this website or get access to its contents.
          </p>
          <p className={styles.paragraph}>
            If you’re over 18 or you’re not underage according to the laws of your country, you automatically agree to
            the TERMS AND CONDITIONS of this website. You also agree explicit sexual content and nudity is not offensive
            to you.
          </p>
          <p className={styles.paragraph}>
            When you click on the “I'm older than 18 years old” button and get into the website, you agree with the
            statements above and certify, under penalty, that you are an adult.
          </p>
          <Link to="/home" className={styles['button-main']}>
            <span>I am older than 18 years old</span>
          </Link>
        </div>
        <span></span>
      </div>
    </Fragment>
  );
};

export default AgeVerification;
