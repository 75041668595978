import { Dispatch, Fragment, SetStateAction, useEffect, useState } from 'react';
import { Link, Outlet, useNavigate, useOutletContext } from 'react-router-dom';
import LottieAnimation from './LottieAnimation';
import styles from './MainLayout.module.scss';
import playButtonAnimation from '../lottie/play-button.json';
import TransitionMask from './TransitionMask';

type Layout = {
  showBackgrounds: boolean;
  showPlayButton: boolean;
};

const layoutDefaults: Layout = {
  showBackgrounds: false,
  showPlayButton: true
};

type ContextType = [
  layout: Layout,
  setLayout: Dispatch<SetStateAction<Layout>>,
  navigateWithTransition: (route: string) => void,
  setTearwingsClass: (color: string) => void
];

const playButtonConfig = { animationData: playButtonAnimation, autoplay: false, loop: false };

const MainLayout = (): JSX.Element => {
  const [layout, setLayout] = useState<Layout>(layoutDefaults);
  const [route, setRoute] = useState<string | null>(null);
  const [tearwingsClass, setTearwingsClass] = useState<string>('');
  const navigate = useNavigate();

  const navigateWithTransition = (newRoute: string): void => {
    setRoute(newRoute);
  };

  const runEnterTransition = () => {
    if (route) {
      navigate(route);
    }
  };

  const runLeaveTransition = () => {
    setRoute(null);
  };

  useEffect(() => {
    setRoute(null);
  }, []);

  return (
    <Fragment>
      <div className={`main-container ${layout.showBackgrounds ? styles['secondary'] : ''}`}>
        <div className={`${styles.background} ${layout.showBackgrounds ? styles['background__secondary'] : ''}`}></div>
        <div className={`header`}>
          <Link to="/home">
            <div className="embla-header-logo" />
          </Link>
        </div>
        <div className="contents" id={'contents'}>
          <Outlet context={[layout, setLayout, navigateWithTransition, setTearwingsClass]} />
        </div>
        <div className={`menu`}>
          <div className="navigation">
            <div className="navigation__top-row">
              <Link to="/play">
                <button className={`button-menu ${styles[tearwingsClass]}`}>
                  <i className="icon icon--diamond"></i>
                  <div>
                    INFORMATION
                    <span />
                  </div>
                </button>
              </Link>
              <Link to="/roadmap">
                <button className={`button-menu ${styles[tearwingsClass]}`}>
                  <i className="icon icon--diamond"></i>
                  <div>
                    ROADMAP
                    <span />
                  </div>
                </button>
              </Link>
              <Link to="/team">
                <button className={`button-menu ${styles[tearwingsClass]}`}>
                  <i className="icon icon--diamond"></i>
                  <div>
                    TEAM
                    <span />
                  </div>
                </button>
              </Link>
              <a href="/assets/litepaper.pdf" target="_blank">
                <button className={`button-menu ${styles[tearwingsClass]}`}>
                  <i className="icon icon--diamond"></i>
                  <div>
                    LITEPAPER
                    <span />
                  </div>
                </button>
              </a>
            </div>
            <div className="navigation__bottom-row">
              {/* <Link to="/terms" className={`${styles[tearwingsClass]}`}>
                Terms of Service
              </Link> */}
              <Link to="/privacy" className={`${styles[tearwingsClass]}`}>
                Privacy Policy
              </Link>
            </div>
          </div>
        </div>
        {layout.showPlayButton && (
          <div className={styles['play-button']}>
            <Link to="/play">
              <span>Play Soon!</span>
              <LottieAnimation lottieConfig={playButtonConfig} animateOnMouseOver={true}></LottieAnimation>
            </Link>
          </div>
        )}
      </div>
      {route && <TransitionMask onEnter={runEnterTransition} onLeave={runLeaveTransition} />}
    </Fragment>
  );
};

export const useLayoutContext = () => {
  return useOutletContext<ContextType>();
};

export default MainLayout;
