import { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';

const WindowScroller = ({ children }: { children: JSX.Element }): JSX.Element => {
  const location = useLocation();

  useLayoutEffect(() => {
    document.documentElement.scrollTo({ top: 0, behavior: 'auto' });
  }, [location.pathname]);

  return children;
};
export default WindowScroller;
