import React, { useEffect } from 'react';
import { useLayoutContext } from '../components/MainLayout';

const Roadmap = () => {
  const [layout, setLayout] = useLayoutContext();

  useEffect(() => {
    setLayout({ showBackgrounds: false, showPlayButton: true });
  }, []);

  return (
    <div className="roadmap-container">
      <div className="team-container__bg"></div>
      <div className="text-title">Roadmap</div>
      <div className="text-title-divider" />
      <div className="roadmap-bars">
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div className="roadmap-row">
        <div className="roadmap-row__date roadmap-row__date--completed">
          <div className="date-completed"></div>
          <div className="roadmap-row__year">Step</div>
          <div className="roadmap-row__quarter">1</div>
        </div>
        <div className="roadmap-row__objectives">
          <ul className="text-list">
            <li>Release of EMBLA NFTs packages</li>
            <li>Gameplay demo</li>
          </ul>
          {/* Completed List */}
          {/* <ul className="text-list">
            <li>
              <div className="goal-completed-container">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit <div className="goal-completed" />
              </div>
            </li>
            <li>
              <div className="goal-completed-container">
                Sed do eiusmod tempor incididunt ut labore
                <div className="goal-completed" />
              </div>
            </li>
            <li>
              <div className="goal-completed-container">
                Ut enim ad minim veniam, quis nostrud exercitation
                <div className="goal-completed" />
              </div>
            </li>
          </ul> */}
        </div>
      </div>
      <div className="roadmap-row-connector" />
      <div className="roadmap-row">
        <div className="roadmap-row__date">
          {/* <div className="date-completed"></div> */}
          <div className="roadmap-row__year">Step</div>
          <div className="roadmap-row__quarter">2</div>
        </div>
        <div className="roadmap-row__objectives">
          <ul className="text-list">
            <li>EMBLA, Silicon Hearts CH 01</li>
            <li>Run and gun voxelart game</li>
            <li>Adult scenarios</li>
          </ul>
        </div>
      </div>
      <div className="roadmap-row-connector" />
      <div className="roadmap-row">
        <div className="roadmap-row__date">
          <div className="roadmap-row__year">Step</div>
          <div className="roadmap-row__quarter">3</div>
        </div>
        <div className="roadmap-row__objectives">
          <ul className="text-list">
            <li>EMBLA, Silicon Hearts CH 02</li>
            <li>New levels and enemies</li>
            <li>Susbcribe waifu feature</li>
            <li>Additional adult scenarios</li>
          </ul>
        </div>
      </div>
      <div className="roadmap-row-connector" />
      <div className="roadmap-row">
        <div className="roadmap-row__date">
          <div className="roadmap-row__year">Step</div>
          <div className="roadmap-row__quarter">4</div>
        </div>
        <div className="roadmap-row__objectives">
          <ul className="text-list">
            <li>EMBLA, Silicon Hearts CH 04</li>
            <li>Evolution of Broken Dolls to EMBLA units</li>
            <li>Generation of NFT adult pictures and videos</li>
            <li>Weapons upgrades</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Roadmap;
