import { useEffect, useRef, useState } from 'react';
import Lottie, { AnimationConfigWithData, AnimationItem } from 'lottie-web';
import styles from './LottieAnimation.module.scss';

interface LottieAnimationProps {
  lottieConfig: Partial<AnimationConfigWithData>;
  lottiePercentage?: number;
  animateOnScreen?: boolean;
  animateOnMouseOver?: boolean;
}

const LottieAnimation = ({
  lottieConfig,
  lottiePercentage,
  animateOnScreen,
  animateOnMouseOver
}: LottieAnimationProps) => {
  const ref = useRef(null);
  const [animation, setAnimation] = useState<AnimationItem | null>(null);

  useEffect(() => {
    const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;

    const defaultConfig = {
      container: ref.current,
      renderer: 'canvas',
      loop: true,
      autoplay: !isFirefox,
      animationData: lottieConfig.animationData
    } as unknown as AnimationConfigWithData;

    if (!animation) {
      Lottie.setQuality('low');
      setAnimation(
        Lottie.loadAnimation({
          ...defaultConfig,
          ...lottieConfig
        })
      );
    }
  }, [lottieConfig]);

  useEffect(() => {
    if (animation && lottiePercentage) {
      const frame = animation.totalFrames * lottiePercentage;
      animation.goToAndStop(frame, true);
    }
  }, [animation, lottiePercentage]);

  useEffect(() => {
    if (animateOnScreen && ref.current) {
      let options = {
        rootMargin: '0px',
        threshold: [1]
      };

      let observer = new IntersectionObserver((entries, entry) => {
        animation?.goToAndPlay(0);
      }, options);
      observer.observe(ref.current);
    }
  }, [animateOnScreen, ref.current]);

  const animateBackwards = () => {
    if (!animateOnMouseOver) {
      return;
    }
    animation?.setDirection(-1);
    animation?.play();
  };

  const animate = () => {
    if (!animateOnMouseOver) {
      return;
    }
    animation?.setDirection(1);
    animation?.play();
  };

  return <div className={styles.container} ref={ref} onMouseEnter={animate} onMouseLeave={animateBackwards} />;
};

export default LottieAnimation;
