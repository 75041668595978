import { Fragment, useEffect } from 'react';
import { useLayoutContext } from '../components/MainLayout';
import styles from './Terms.module.scss';

const Privacy = () => {
  const [layout, setLayout] = useLayoutContext();

  useEffect(() => {
    setLayout({ showBackgrounds: false, showPlayButton: false });
  }, []);

  return (
    <Fragment>
      <div className={styles.bg}></div>
      <div className={styles.main}>
        <h2 className={styles.title}>Privacy Policy</h2>
        <section className={styles.content}>
          <p className="text-body">
            The following Privacy Policy establishes the terms that{' '}
            <span className="text-highlight">Kimochi Games SAS</span> use to protect the information provided by users
            when they use our website. This company is committed to the security of the users' data. When we ask you to
            fill in the personal details fields that can be used to identify you, we assure you that is done according
            to the terms of this document. However, this Privacy Policy can change through time or be updated for that
            reason we recommend you to check this website regularly to be sure you agree with such changes.
          </p>
          <p className="text-body">
            <span className={styles['paragraph-title']}>Use of the collected information</span> <br />
            Our website uses the details in order to provide the best service possible, particularly to keep users'
            records, order if applied, and improve our products and services. It is possible that emails could be sent
            frequently through our website with special offers, new products and other advertisements we consider
            relevant for you or that could bring you any sort of benefit, such emails will be sent to the e-mail address
            you provide and can be opted out at any moment.
          </p>
          <p className="text-body">
            <span className="text-highlight">Kimochi Games SAS</span> is highly committed to fulfilling its commitment
            to keeping your details safe. We use the most advanced systems and we update them regularly to be sure there
            is no unauthorized access.
          </p>
          <p className="text-body">
            <span className={styles['paragraph-title']}>Cookies</span> <br /> A cookie refers to a file that is sent to
            request permission to be stored on your computer. When you accept the Privacy Policy, such a file is created
            and it works then to deal with information regarding web traffic and it also helps with future visits to a
            recurring web. Cookies can also enable websites to recognize you individually and thus bring you the best
            personalized service on your web.
          </p>
          <p className="text-body">
            Our website uses cookies to be able to identify visited websites and their frequency. Such information is
            used only for statistical analysis and then this info is deleted permanently. You can delete cookies from
            your computer at any time. However, cookies help to provide the best service possible from websites. They
            don’t grant access to your computer or your information unless you want that and you provide it directly.
            You can accept or decline cookies usage, although, most browsers accept them automatically since that works
            for getting a better web service. Also, you can change your system set up to decline cookies. If you decline
            them, you might not be able to use some of our services.
          </p>
          <p className="text-body">
            <span className={styles['paragraph-title']}>Links to Third Parties</span> <br /> This website could have
            links to other websites that could be of your interest. Once you click on any of these links and quit our
            website, we will not have any control over the restricted third-party website. Hence, we will not be
            responsible for the terms, the privacy, or the protection of your data while you browse third-party
            websites. Such websites will be subject to their own Privacy Policies therefore we recommend you reach out
            to confirm you agree with them.
          </p>
          <p className="text-body">
            <span className={styles['paragraph-title']}>Control over Personal Details</span> <br />
            You can restrict compiling or use of your personal details that are provided to our website at any time.
            Each time you are requested to fill out a form, e.g unsubscribe, you can mark or unmark the receiving
            newsletter option. If you have checked the newsletter or advertisements option you can cancel it at any
            time.
          </p>
          <p className="text-body">
            This company will not sell, give away or distribute your personal details that are collected without your
            consent, save it is requested by a judge in a court.
          </p>
          <p className="text-body">
            <span className="text-highlight">Kimochi Games SAS</span> will keep the right to change the Terms and
            Conditions of the current Privacy Policy at any time.
          </p>
        </section>
      </div>
    </Fragment>
  );
};

export default Privacy;
