import { createPortal } from 'react-dom';
import { Link } from 'react-router-dom';
import styles from './WhiteEmblaLogo.module.scss';

const WhiteEmblaLogo = (): JSX.Element => {
  return createPortal(
    <Link to="/home" className={styles['embla-white-link']}>
      <img src="assets/embla-logo-white.svg" alt="Embla" />
    </Link>,
    document.getElementById('overlay-root') as HTMLElement
  );
};

export default WhiteEmblaLogo;
