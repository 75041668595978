import { Fragment, useEffect, useState } from 'react';
import LottieAnimation from '../components/LottieAnimation';
import styles from './Play.module.scss';
import { useScroll } from '../hooks/scroll.hook';
import introAnimation from '../lottie/intro.json';
import saiAnimation from '../lottie/sai-g.json';
import scrollAnimation from '../lottie/scroll-notification.json';
import wiredEmblaAnimation from '../lottie/embla-on-right.json';
import emblaAnimation from '../lottie/embla-on-right-nude.json';
import Tearwings from '../components/Tearwings';
import { useLayoutContext } from '../components/MainLayout';
import system1 from '../scss/images/system1.png';
import system2 from '../scss/images/system2.png';
import gauntlets from '../scss/images/gauntlets.png';
import embla from '../scss/images/embla.png';
import brokenDoll from '../scss/images/brokenDoll.png';

const introConfig = { animationData: introAnimation };
const saiConfig = { animationData: saiAnimation, autoplay: false, loop: false };
const scrollConfig = { animationData: scrollAnimation, autoplay: true, loop: true };
const wiredEmblaConfig = { animationData: wiredEmblaAnimation, autoplay: false };
const emblaConfig = { animationData: emblaAnimation, loop: false, autoplay: false };

const Play = () => {
  const [layout, setLayout, navigateWithParamteres, setMenuColor] = useLayoutContext();
  const [barHeight, setBarHeight] = useState(0);

  useEffect(() => {
    setLayout({ showBackgrounds: true, showPlayButton: true });
    return () => {
      setMenuColor('');
    };
  }, []);

  const { percentage: windowPercentage } = useScroll({ offset: window.innerHeight });
  useEffect(() => {
    const scrollMenuFillHeight = 422;
    const height = scrollMenuFillHeight * windowPercentage;
    setBarHeight(height < 0 ? 0 : height);
    const color = windowPercentage > 0.8 ? 'green' : '';
    setMenuColor(color);
  }, [windowPercentage]);

  return (
    <Fragment>
      <div className="scroll-menu">
        <div className="scroll-menu__top"></div>
        <div className="scroll-menu__fill" style={{ height: barHeight }} />
        <div className="scroll-menu__fillers">
          <div className={`scroll-menu__filler ${windowPercentage > 0.01 ? 'visible' : ''}`} />
          <div className={`scroll-menu__filler ${windowPercentage > 0.31 ? 'visible' : ''}`} />
          <div className={`scroll-menu__filler ${windowPercentage > 0.63 ? 'visible' : ''}`} />
          <div className={`scroll-menu__filler ${windowPercentage > 0.95 ? 'visible' : ''}`} />
        </div>
        <div className="scroll-menu__buttons">
          <a href="#saig" className={`scroll-menu__item ${windowPercentage > 0.85 ? styles['green'] : ''}`}>
            SAI-G
            <span />
          </a>
          <a href="#systems" className={`scroll-menu__item ${windowPercentage > 0.85 ? styles['green'] : ''}`}>
            Systems
            <span />
          </a>
          <a href="#artifacts" className={`scroll-menu__item ${windowPercentage > 0.85 ? styles['green'] : ''}`}>
            Artifacts
            <span />
          </a>
          <a href="#tearwings" className={`scroll-menu__item ${windowPercentage > 0.85 ? styles['green'] : ''}`}>
            Tearwings
            <span />
          </a>
        </div>
      </div>
      <section className={styles['main-section']}>
        <div className={styles['main-section__bg']}>
          <div className={styles['main-section__bg__content']}>
            <LottieAnimation lottieConfig={introConfig} />
          </div>
        </div>
        <span></span>
        <span>
          <h1 className={styles['main-section__title']}>Witness an angel’s birth</h1>
          <h3 className={styles['main-section__subtitle']}>An adult visual novel for the Web 3.0 era</h3>
        </span>
        <div className={styles['main-section__scroll']}>
          <span>scroll</span>
          <LottieAnimation lottieConfig={scrollConfig} />
        </div>
      </section>
      <div className="play-container">
        <div className="play-one" id="saig">
          <div className={styles['sai-bg']}></div>
          <div className={styles['sai-icon']}>
            <LottieAnimation lottieConfig={saiConfig} animateOnScreen={true} />
          </div>
          <div className="text-title">Greetings from SAI-G</div>
          <div className="text-body">
            You won <span className="text-highlight">SAI-G’s</span> raffle for beta testing EMBLA: the most advanced AI
            unit of the famous technology company. The only condition is that you train its cognitive modules, which
            will slowly develop the EMBLA unit’s perception of humanity. Become one of the first fortunate to interact
            with the ultimate being.
          </div>
          <ul className="text-list">
            <li>
              EMBLA is as an adult visual novel with <span className="text-highlight">asymmetrical</span> gameplay
              elements.
            </li>
            <li>
              Only a few will be the fortunate owners of limited EMBLA units and will get access to their story
              progression.
            </li>
            <li>
              Others may get access to <span className="text-highlight">Broken Dolls</span>, which can contribute their
              chosen EMBLAs to push them through the stories scenarios, but will disrupt their systems while doing so.
            </li>
          </ul>
        </div>
        <div className="play-two" id="systems">
          <img className="play-image" src={system1} alt="system-1" />
          <div className="text-title">Mini Games for Collecting Materials</div>
          <ul className="text-list">
            <li>Action mini-games to earn in-game currency.</li>
          </ul>
          <img className="play-image" src={system2} alt="system-2" />
          <div className="text-title">Upgrade EMBLA units’ cognitive scenarios</div>
          <ul className="text-list">
            <li>
              <span className="text-highlight">EMBLA unit’s users</span> can unlock story progression and sex scenes for
              their unique characters.
            </li>
            <li>
              <span className="text-highlight">Broken Doll’s users</span> can decide which EMBLA unit to support by
              donating them their earned currencies.
            </li>
            <li>
              It lays on the EMBLA unit’s users to display and perform their EMBLA unit’s fully interactive scenes to
              their supporters.
            </li>
          </ul>
        </div>
        <div className="play-three" id="artifacts">
          <img className="play-image" src={gauntlets} alt="system-2" />
          <div className="text-title">NFTs: Weapons</div>
          <ul className="text-list">
            <li>Unique VoxelArt NFT weapons for better proficiency on mini-games.</li>
          </ul>
          <img className="play-image" src={embla} alt="system-2" />
          <div className="text-title">NFTs: EMBLA units</div>
          <ul className="text-list">
            <li>Unique limited amount of NFTs: Access to the story scenarios with the EMBLA unit as protagonist.</li>
            <li>
              Access to the mini games that will win the necessary materials for developing the EMBLA unit’s cognitive
              modules.
            </li>
            <li>After developing a cognitive module, an interactive sex scene will be unlocked.</li>
          </ul>
          <img className="play-image" src={brokenDoll} alt="system-2" />
          <div className="text-title">NFTs: Broken Dolls</div>
          <ul className="text-list">
            <li>Unique NFTs: Access to the opposing side of the story scenario using a Broken Doll to advance.</li>
            <li>
              Access to the mini-games that will win the necessary materials for developing an EMBLA unit’s cognitive
              modules.
            </li>
            <li>Can choose which EMBLA unit to support with their collected materials.</li>
          </ul>
        </div>
        <Tearwings />
      </div>
      <div className={`${styles['embla-wired']}`}>
        {windowPercentage > 0.01 && (
          <div className={`${styles['embla-wired__content']}`}>
            <LottieAnimation lottieConfig={emblaConfig} lottiePercentage={windowPercentage} />
          </div>
        )}
        <div className={`${styles['embla-wired__content']}`}>
          <LottieAnimation lottieConfig={wiredEmblaConfig} />
        </div>
      </div>
    </Fragment>
  );
};

export default Play;
