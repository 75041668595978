import { Fragment, useEffect } from 'react';
import styles from './Main.module.scss';

import LottieAnimation from '../components/LottieAnimation';
import firefoxBg from '../scss/images/main-firefox-bg.png';
import emblaIntroAnimation from '../lottie/embla-intro.json';
import enterAnimation from '../lottie/enter-button.json';
import { useLayoutContext } from '../components/MainLayout';

const emblaIntroConfig = { animationData: emblaIntroAnimation };
const enterConfig = { animationData: enterAnimation };

const Main = () => {
  const [layout, setLayout, navigateWithTransition] = useLayoutContext();
  const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;

  useEffect(() => {
    setLayout({ showBackgrounds: false, showPlayButton: true });
  }, []);

  return (
    <Fragment>
      <div className={styles.background}>
        {isFirefox ? (
          <img src={firefoxBg} />
        ) : (
          <div className={styles['background__content']}>
            <LottieAnimation lottieConfig={emblaIntroConfig} />
          </div>
        )}
      </div>
      <button className={styles.link} onClick={() => navigateWithTransition('/play')}>
        <span>CLICK ENTER</span>
        <LottieAnimation lottieConfig={enterConfig} />
      </button>
    </Fragment>
  );
};

export default Main;
