import { useEffect, useState } from 'react';

export const useScroll = ({ offset }: { offset: number }) => {
  const [percentage, setPercentage] = useState(0);

  const handleScroll = () => {
    const scrollTop = offset ? window.scrollY - offset : window.scrollY;
    const docHeight = offset ? document.body.offsetHeight - offset : document.body.offsetHeight;
    const winHeight = window.innerHeight;
    const scrollPercentage = scrollTop / (docHeight - winHeight);
    setPercentage(scrollPercentage);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return { percentage };
};
