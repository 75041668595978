import { TransitionEvent, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import styles from './TransitionMask.module.scss';

const TransitionMask = ({ onEnter, onLeave }: { onEnter: () => void; onLeave: () => void }): JSX.Element => {
  const [enterTransitionClass, setEnterTransitionClass] = useState('');
  const [leaveTransitionClass, setLeaveTransitionClass] = useState('');
  const safeEndTransition = () =>
    setTimeout(() => {
      onEnter();
      setLeaveTransitionClass(styles['main--leave']);
    }, 4000);

  useEffect(() => {
    setEnterTransitionClass(styles['main__mask--enter']);
    const transitionTimeout = safeEndTransition();
    return () => {
      clearTimeout(transitionTimeout);
    };
  }, []);

  const onTransitionEnter = (event: TransitionEvent<HTMLImageElement>) => {
    event.stopPropagation();
    setLeaveTransitionClass(styles['main--leave']);
    onEnter();
  };

  return createPortal(
    <div className={`${styles.main} ${leaveTransitionClass}`} onTransitionEnd={onLeave}>
      <img
        className={`${styles['main__mask']} ${enterTransitionClass}`}
        src="assets/embla-transition-mask.svg"
        alt="transition"
        onTransitionEnd={onTransitionEnter}
      />
    </div>,
    document.getElementById('overlay-root') as HTMLElement
  );
};

export default TransitionMask;
