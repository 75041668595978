import React, { useEffect } from 'react';
import { useLayoutContext } from '../components/MainLayout';

const Team = () => {
  const [layout, setLayout] = useLayoutContext();

  useEffect(() => {
    setLayout({ showBackgrounds: false, showPlayButton: true });
  }, []);
  return (
    <div className="team-container">
      <div className="team-container__bg"></div>
      <div className="team-top">
        <div className="team-top__left">
          <div className="text-title">Kimochi Games</div>
          <div className="text-title-divider" />
          <div className="text-body">
            <div>
              Dedicated to the development of +18 video games. Our objectives are to provide compelling stories with top
              quality art and engaging game-play that can accompany the adult gaming experience.
            </div>
            <br />
            <a className="text-link" href="https://kimochi.games/" target="_blank" rel="noreferrer">
              Homepage
            </a>
          </div>
          {/* <div className="text-title" style={{ marginBottom: '2rem' }}>
            Development Team
          </div> */}
          {/* <ul className="text-list">
            <li>
              <div className="text-list__columns">
                <span>Chief Lust Executive</span>
                <span className="text-position">Producer</span>
                <a className="text-link">Link</a>
              </div>
              <div className="text-list__divider" />
            </li>
            <li>
              <div className="text-list__columns">
                <span>Cotton Bottom</span>
                <span className="text-position">Game Designer</span>
                <a href="https://twitter.com/bottom_cotton" target="_blank" className="text-link">
                  Twitter
                </a>
              </div>
              <div className="text-list__divider" />
            </li>
            <li>
              <div className="text-list__columns">
                <span>Architect of Orgasms</span>
                <span className="text-position">Developer</span>
                <a className="text-link">Link</a>
              </div>
              <div className="text-list__divider" />
            </li>
            <li>
              <div className="text-list__columns">
                <span>Master of Living Fervor</span>
                <span className="text-position">Artist</span>
                <a className="text-link">Link</a>
              </div>
              <div className="text-list__divider" />
            </li>
            <li>
              <div className="text-list__columns">
                <span>Orgies Architect</span>
                <span className="text-position">Developer</span>
                <a className="text-link">Link</a>
              </div>
              <div className="text-list__divider" />
            </li>
            <li>
              <div className="text-list__columns">
                <span>Nombre Apellido</span>
                <span className="text-position">Posicion</span>
                <a className="text-link">Link</a>
              </div>
              <div className="text-list__divider" />
            </li>
            <li>
              <div className="text-list__columns">
                <span>Nombre Apellido</span>
                <span className="text-position">Posicion</span>
                <a className="text-link">Link</a>
              </div>
              <div className="text-list__divider" />
            </li>
            <li>
              <div className="text-list__columns">
                <span>Nombre Apellido</span>
                <span className="text-position">Posicion</span>
                <a className="text-link">Link</a>
              </div>
              <div className="text-list__divider" />
            </li>
          </ul> */}
        </div>
        <div className="team-top__right">
          <div className="text-title">Ashara Studios</div>
          <div className="text-title-divider" />
          <div className="text-body">
            <div>
              Company dedicated to the development of video games and other software products. With 6 years of
              experience, is now focused on making interactive experiences for Web3.
            </div>
            <br />
            <a className="text-link" href="https://asharastudios.com/" target="_blank" rel="noreferrer">
              Homepage
            </a>
          </div>
          <br />
          <br />
          <div className="text-title">Chrysalis Guild</div>
          <div className="text-title-divider" />
          <div className="text-body">
            <div>
              Decentralized organization that promotes gaming as a sustainable life-style through the removal of
              barriers to enter games and other interactive experiences.
            </div>
            <br />
            <a className="text-link" href="https://chrysalisguild.gg/" target="_blank" rel="noreferrer">
              Homepage
            </a>
          </div>
        </div>
      </div>
      {/* <div className="team-bottom">
        <div className="text-title">Partners</div>
        <div className="text-title-divider" />
        <div className="team-bottom__partner-links">
          <ul className="text-list">
            <li>
              <a className="text-link">Partner Link</a>
            </li>
            <li>
              <a className="text-link">Partner Link</a>
            </li>
            <li>
              <a className="text-link">Partner Link</a>
            </li>
          </ul>
          <ul className="text-list">
            <li>
              <a className="text-link">Partner Link</a>
            </li>
            <li>
              <a className="text-link">Partner Link</a>
            </li>
            <li>
              <a className="text-link">Partner Link</a>
            </li>
          </ul>
        </div>
      </div> */}
    </div>
  );
};

export default Team;
