import { Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import './reset.css';
import './scss/index.scss';

import Play from './pages/Play';
import Team from './pages/Team';
import Roadmap from './pages/Roadmap';
import Main from './pages/Main';
import MainLayout from './components/MainLayout';
import Terms from './pages/Terms';
import AgeVerification from './pages/AgeVerification';
import WindowScroller from './components/WindowScroller';
import Privacy from './pages/Privacy';

const App = () => {
  return (
    <BrowserRouter>
      <WindowScroller>
        <Suspense fallback={<div>loading</div>}>
          <Routes>
            <Route index element={<AgeVerification />} />
            <Route path="/" element={<MainLayout />}>
              <Route path="home" element={<Main />} />
              <Route path="play" element={<Play />} />
              <Route path="roadmap" element={<Roadmap />} />
              <Route path="team" element={<Team />} />
              <Route path="terms" element={<Terms />} />
              <Route path="privacy" element={<Privacy />} />
            </Route>
          </Routes>
        </Suspense>
      </WindowScroller>
    </BrowserRouter>
  );
};

export default App;
