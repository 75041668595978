import { useEffect, useRef, useState } from 'react';
import styles from './Tearwings.module.scss';
import tearwingsAnimation from '../lottie/tearwings-one.json';
import tearwingsForegroundAnimation from '../lottie/tearwings-two.json';
import LottieAnimation from './LottieAnimation';
import WhiteEmblaLogo from './WhiteEmblaLogo';

const tearwingsConfig = { animationData: tearwingsAnimation, autoplay: false };
const tearwingsForegroundConfig = { animationData: tearwingsForegroundAnimation, autoplay: false };

const Tearwings = (): JSX.Element => {
  const tearwingsRef = useRef(null);
  const [tearwingsPercentage, setTearwingsPercentage] = useState(0);

  const isMobile = window.innerWidth < window.innerHeight;

  const buildThresholdList = (numSteps: number) => {
    let thresholds = [];

    for (let i = 1; i <= numSteps; i++) {
      const ratio = i / numSteps;
      thresholds.push(ratio);
    }

    thresholds.push(0);
    return thresholds;
  };

  useEffect(() => {
    if (tearwingsRef.current) {
      let options = {
        root: null,
        rootMargin: '-100px',
        threshold: buildThresholdList(100)
      };

      let observer = new IntersectionObserver((entries, obs) => {
        entries.forEach(function (entry) {
          const percentage = Math.round(entry.intersectionRatio * 100) / 100;
          setTearwingsPercentage(percentage);
        });
      }, options);
      observer.observe(tearwingsRef.current);
    }
  }, [tearwingsRef.current]);
  return (
    <div ref={tearwingsRef} className={`play-four ${styles.tearwings}`} id="tearwings">
      {tearwingsPercentage && <WhiteEmblaLogo />}
      <div className={`${styles['tearwings__background']} ${tearwingsPercentage < 0.01 || isMobile ? 'hidden' : ''}`}>
        <div className={`${styles['tearwings__background__content']}`}>
          <LottieAnimation lottieConfig={tearwingsConfig} lottiePercentage={tearwingsPercentage} />
        </div>{' '}
      </div>
      <img className={styles['tearwings__icon']} src="assets/tearwings.svg" alt="Tearwings" />
      <div className={`text-title ${styles['tearwings__highlight']} ${styles['tearwings__title']}`}>
        Tear their wings out
      </div>
      <div className={`text-body ${styles['tearwings__content']}`}>
        After losing SAI-G’s raffle, you were contacted by a subversive group called Tearwings. They conveyed you the
        secrets behind the powerful organization, and invited you to join the resistance. Use one of SAI-G’s own
        discarded <span className={styles['tearwings__highlight']}>Broken Dolls</span> to fight the EMBLA menace
      </div>
      <ul className={`text-list ${styles['tearwings__list']}`}>
        <li>The Broken Dolls’ owners may provide materials to the owner of an EMBLA of their choosing.</li>
        <li>This will help the EMBLA they select to develop quicker.</li>
        <li>The EMBLA owners have to find ways to attract more Broken Doll users.</li>
      </ul>
      <div className={`${styles['tearwings__foreground']} ${tearwingsPercentage < 0.01 || isMobile ? 'hidden' : ''}`}>
        <LottieAnimation lottieConfig={tearwingsForegroundConfig} lottiePercentage={tearwingsPercentage} />
      </div>
      {isMobile && (
        <div className={`${styles['tearwings__mobile_bg']} ${tearwingsPercentage < 0.01 ? 'hidden' : ''}`}></div>
      )}
    </div>
  );
};

export default Tearwings;
